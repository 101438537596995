import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CtxPlanResponse } from '../types/response.types';
import {
  MAX_INT32,
  MAX_INT32_DAYS,
  MIN_INT32_DAYS,
  isValidUrl,
  isVersion,
} from './validators';

/** Class containing custom validators for FormControls */
export class CtxValidators {
  /**
   * @description
   * Validator that requires the control's value not more than 5 elements.
   */
  static tags: ValidatorFn = (control: AbstractControl) => {
    const tags = control.value;
    if (Array.isArray(tags) && tags.length > 5) {
      return { message: 'Maximum 5 tags are allowed only.' };
    }
    return null;
  };

  /**
   * @description
   * Validator that requires the control's value in the format of 1.0, 1.0.0, 1.2.3.4 etc.
   */

  static version: ValidatorFn = (control: AbstractControl) => {
    const version: unknown = control.value;

    if (typeof version === 'string' && !isVersion(version)) {
      return {
        semver:
          'The version should follow SemVer formatting (e.g. 1.0, 1.0.0, 1.2.3.4)',
      };
    }

    return null;
  };

  /**
   * @description
   * Validator that requires the control's value in 32-bit range.
   */
  static int32: ValidatorFn = (control: AbstractControl) => {
    return Validators.max(MAX_INT32)(control);
  };

  static url: ValidatorFn = (control: AbstractControl) => {
    const value = control.value;
    if (typeof value === 'string' && !isValidUrl(value)) {
      return {
        url: 'Enter a valid HTTP/HTTPS URL',
      };
    } else {
      return null;
    }
  };

  /**
   * @description
   * Validator that ensures the control's value falls within a 32-bit range, considering days converted to seconds.
   */
  static maxDays: ValidatorFn = (control: AbstractControl) => {
    return Validators.max(MAX_INT32_DAYS)(control);
  };

  /**
   * @description
   * Validator that ensures the control's value falls within a 32-bit range, considering days converted to seconds.
   */
  static minDays: ValidatorFn = (control: AbstractControl) => {
    return Validators.min(MIN_INT32_DAYS)(control);
  };

  static notZero: ValidatorFn = (control: AbstractControl) => {
    if (Number(control.value) === 0) {
      return {
        message: 'Value cannot be 0',
      };
    } else {
      return null;
    }
  };

  static onlyIntegers: ValidatorFn = (control: AbstractControl) => {
    if (Validators.pattern('^-?[0-9]*$')(control)) {
      return {
        message: 'Value should be an integer',
      };
    } else {
      return null;
    }
  };
  /**
   * @description
   * Custom Validator for Server Sync Interval based on Account Plan.
   */
  static serverSyncInterval(plan: CtxPlanResponse | undefined): ValidatorFn {
    const allowedSsi = [
      'small_business',
      'small_business_annual',
      'small_business_annual_legacy',
      'business',
      'business_legacy',
      'business_annual',
      'business_annual_legacy',
      'enterprise1',
      'enterprise1_legacy',
      'enterprise1_annual',
      'enterprise1_annual_legacy',
      'enterprise2',
      'enterprise2_annual',
      'enterprise3',
      'enterprise3_annual',
    ];

    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!plan) {
        return null;
      } else if (allowedSsi.includes(plan.name)) {
        return null;
      } else if (value < 3600) {
        return {
          message:
            'Your plan does not allow a server sync interval less than 3600 seconds.',
        };
      } else {
        return null;
      }
    };
  }
  /**
   * @description
   * Validator that ensures FormArrays instances of Meterdata and Metadata don't have duplicates
   */
  static uniqueKeys(keyname: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dataArray = control;

      if (!dataArray || !Array.isArray(dataArray.value)) {
        return null;
      }
      if (dataArray instanceof FormArray) {
        const keysSet = new Set<string>();

        dataArray.controls.forEach((item: AbstractControl) => {
          if (
            item instanceof FormGroup &&
            item.value &&
            keyname in item.value
          ) {
            const key = item.value[keyname]; //keyname is the name of the control holding the key

            if (keysSet.has(key)) {
              item.controls[keyname].setErrors({ duplicateKeys: key }); //setError to the field having duplicate keyname
            } else {
              keysSet.add(key);
            }
          }
        });
      }
      return null;
    };
  }

  static formArrayMinimum(key: string, minVal: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dataArray = control;

      if (!dataArray || !Array.isArray(dataArray.value)) {
        return null;
      }
      if (dataArray instanceof FormArray) {
        dataArray.controls.forEach((item: AbstractControl) => {
          if (
            item instanceof FormGroup &&
            item.value &&
            item.value?.[key] < minVal
          ) {
            item.controls[key].setErrors({
              'forbidden value': `Value should be equal to or greater than ${minVal}`,
            });
          }
        });
      }
      return null;
    };
  }

  static oidc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control instanceof FormGroup) {
        const autoProvisionUsers = control.get('autoProvisionUsers');
        const userRoleMapping = control.get('claimMapping.role');

        if (userRoleMapping) {
          if (autoProvisionUsers && autoProvisionUsers.value) {
            userRoleMapping.addValidators(Validators.required);
          } else {
            userRoleMapping.removeValidators(Validators.required);
          }
          userRoleMapping.updateValueAndValidity({ onlySelf: true });
        }
      }
      return null;
    };
  }
  /** Validate license forms (license, license-template) */
  static license: ValidatorFn = (control: AbstractControl) => {
    const LEASE_DURATION_FLOATING_VALS = [
      Validators.required,
      Validators.min(-1),
      CtxValidators.int32,
      CtxValidators.onlyIntegers,
    ];
    const LEASE_DURATION_ONPREMISE_VALS = [
      Validators.required,
      Validators.min(0),
      CtxValidators.int32,
      CtxValidators.onlyIntegers,
    ];
    const ALLOW_CLOCK_OFFSET_VALS = [
      Validators.required,
      CtxValidators.int32,
      CtxValidators.onlyIntegers,
    ];
    if (control instanceof FormGroup) {
      const leaseDuration = control.get('leaseDuration');
      const serverSyncGracePeriod = control.get('serverSyncGracePeriod');
      if (
        serverSyncGracePeriod?.value > -1 &&
        serverSyncGracePeriod?.value < 0
      ) {
        serverSyncGracePeriod?.setErrors({
          'forbidden value':
            'ServerSyncGracePeriod is either -1 or a non-negative number',
        });
      }
      const rawValue: unknown = control.getRawValue();
      if (
        rawValue &&
        typeof rawValue === 'object' &&
        'type' in rawValue &&
        typeof rawValue.type === 'string'
      ) {
        const type = rawValue.type;
        // Validator for allowClockOffset, allow -1 only for perpetual node-locked licenses
        const allowedClockOffset = control.get('allowedClockOffset');
        const validity = Number(control.getRawValue().validity);

        const minValidator =
          type === 'node-locked' && validity === 0
            ? Validators.min(-1)
            : Validators.min(0);
        allowedClockOffset?.setValidators([
          ...ALLOW_CLOCK_OFFSET_VALS,
          minValidator,
        ]);
        allowedClockOffset?.updateValueAndValidity({ onlySelf: true });
        if (type === 'hosted-floating') {
          leaseDuration?.setValidators(LEASE_DURATION_FLOATING_VALS);
          if (leaseDuration?.value > -1 && leaseDuration?.value < 180) {
            leaseDuration?.setErrors({
              'forbidden value':
                'Lease Duration should be equal to or greater than 180 or -1',
            });
          }
          return null;
        } else if (type === 'on-premise-floating') {
          leaseDuration?.setValidators(LEASE_DURATION_ONPREMISE_VALS);
          return null;
        } else if (type === 'node-locked') {
          // Remove all leaseDuration validators
          leaseDuration?.setValidators(null);
          // Remove all leaseDuration errors since the input isn't shown and might not be updated.
          leaseDuration?.setErrors(null);

          return null;
        } else {
          return null;
        }
      }
      return null;
    } else {
      return null;
    }
  };
  /**
   * @description
   * Validator that ensures the new password and confirm new password fields match.
   */
  static passwordMatch: ValidatorFn = (control: AbstractControl) => {
    const newPassword = control.get('newPassword')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    if (newPassword !== confirmPassword) {
      control.get('confirmPassword')?.setErrors({
        mismatch: 'Passwords do not match.',
      });
    }
    return null;
  };

  /**
   * @description
   * Validator that ensures the new password and confirm new password fields match.
   */
  static duplicateCustomDomains: ValidatorFn = (control: AbstractControl) => {
    const customerPortalDomain = control.getRawValue().customerPortalDomain;
    const resellerPortalDomain = control.getRawValue().resellerPortalDomain;
    const error = {
      duplicate: "Two Portals can't  point to the same custom domain",
    };
    if (customerPortalDomain && customerPortalDomain === resellerPortalDomain) {
      control.get('customerPortalDomain')?.setErrors(error);
    }
    if (resellerPortalDomain && customerPortalDomain === resellerPortalDomain) {
      control.get('resellerPortalDomain')?.setErrors(error);
    }
    return null;
  };
}
