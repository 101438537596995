import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ProfileService } from '../../_services/profile.service';
import { CtxButtonComponent } from '../button/button.component';
import saveAs from 'file-saver';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CtxAlertComponent } from '../alert/alert.component';
@Component({
  selector: 'ctx-reseller-form',
  templateUrl: './recovery-codes.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    CtxButtonComponent,
    MatProgressSpinnerModule,
    CtxAlertComponent,
  ],
})
export class RecoveryCodesComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogRef: MatDialogRef<RecoveryCodesComponent>,
    public profileService: ProfileService
  ) {}
  loading = true;
  async ngOnInit(): Promise<void> {
    this.recoveryCodes = await this.profileService.getTwoFactorRecoveryCodes();
    this.loading = false;
  }
  recoveryCodes: string[];

  downloadRecoveryCodes() {
    const blob = new Blob([this.recoveryCodes.join(',')], {
      type: 'text/plain',
    });
    saveAs(blob, `recovery-codes`);
    this.dialogRef.close(true);
  }
}
