<h2 mat-dialog-title>Recovery Codes</h2>
<mat-dialog-content>
  @if(!loading){
  <section class="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4">
    @for (code of recoveryCodes; track $index) {
    <code class="text-center">{{ code }}</code>
    }
  </section>
  }@else {
  <mat-spinner class="mx-auto"></mat-spinner>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button matDialogClose ctx-button--secondary [disabled]="loading">
    Close
  </button>
  <button
    ctx-button--secondary
    actionIcon="DOWNLOAD"
    type="button"
    (click)="downloadRecoveryCodes()"
    [disabled]="loading"
  >
    Download
  </button>
</mat-dialog-actions>
